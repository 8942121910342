import React from 'react'
import { Link } from 'gatsby'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Grid } from '@material-ui/core'

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      style={{ textAlign: 'center', margin: '10px 0px' }}
    >
      {'Copyright © '}
      <Link color="inherit" to="/">
        WSH Entertainment
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '90vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[700]
        : theme.palette.grey[800],
  },
}))

export default function Footer() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <footer className={classes.footer}>
        <Container maxWidth={'md'}>
          <CssBaseline />
          <Grid alignItems={'center'} justify={'center'} container spacing={6}>
            {/* <Grid item>
              <Link to="/about">About</Link>
            </Grid> */}
            <Grid item>
              <Link to="/booking">Booking</Link>
            </Grid>
            <Grid item>
              <Link to="/videography">Videography</Link>
            </Grid>
            <Grid item>
              <Link to="/photography">Photography</Link>
            </Grid>
            <Grid item>
              <Link to="/store">Store</Link>
            </Grid>
          </Grid>
          <Copyright />
        </Container>
      </footer>
    </div>
  )
}
