/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes, { any } from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Container, ThemeProvider } from '@material-ui/core'

import Header from './header'
import Footer from './footer'
import * as CSS from 'csstype'
import './layout.css'
import { primaryLight } from '../theme/AppThemes'

const containerStyles: CSS.Properties = {
  minHeight: 'calc(100vh - 150px)',
  paddingTop: '60px',
}

interface Props {
  styles?: {
    maxWidth: any
    paddingLeft: any
    paddingRight: any
    marginLeft: any
    marginRight: any
  }
}

export const Layout = ({ children }: any, props: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <ThemeProvider theme={primaryLight}>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Container
            disableGutters
            maxWidth={false}
            style={{ ...containerStyles, width: '100%' }}
          >
            {children}
          </Container>
          <Footer />
        </ThemeProvider>
      </React.Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
