export const Colors = {
  purple: {
    100: '#EDEBFF',
    300: '#AEA7E8',
    500: '#675EB5',
    700: '#3E3783',
    900: '#20194F',
  },
  teal: {
    100: '#CCFFFF',
    300: '#6CE6E6',
    500: '#2BC2C2',
    700: '#0F8080',
    900: '#026363',
  },
  yellow: {
    100: '#FFEEA3',
    300: '#F6D548',
    500: '#C2A525',
    700: '#87710C',
    900: '#5C4B00',
  },
  grey: {
    100: '#EFEDF2',
    300: '#B6B5BA',
    500: '#8A878C',
    700: '#5A545C',
    900: '#1F1B21',
  },
  green: {
    100: '#2EC4B6', // yanni's color
    300: '#37CC98',
    500: '#0B9967',
    700: '#006642',
    900: '#003321',
  },
  orange: {
    100: '#FFCBA3',
    300: '#F59953',
    500: '#C2631B',
    700: '#8F4106',
    900: '#662C00',
  },
  red: {
    100: '#F59393',
    300: '#EB5E5E',
    500: '#B82525',
    700: '#850D0D',
    900: '#520000',
  },
  blue: {
    100: '#A8D3F0',
    300: '#56A3D6',
    500: '#216FA3',
    700: '#0B4870',
    900: '#00253D',
  },
  yanni: {
    darkBlue: '#011627',
    red: '#FF3366',
    mint: '#2EC4B6',
    white: '#F6F7F8',
    blue: '#20A4F3',
  },
}
