import React from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { Colors } from './Colors'
import { XCircle } from 'react-feather'
import '../css/style.css'

export const primaryLight = createMuiTheme({
  typography: {
    // fontFamily: `'Luckiest Guy', cursive;`,
    h1: {
      fontWeight: 'normal',
      fontSize: '38px',
      lineHeight: '32px',
      fontFamily: `'Luckiest Guy', cursive;`,
    },
    h2: {
      // fontWeight: 'bold',
      fontSize: '30px',
      lineHeight: '32px',
      fontFamily: `'Ruslan Display', cursive;`,
    },
    h3: {
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '24px',
      color: Colors.grey[900],
      fontFamily: `'Rubik', sans-serif;`
    },
    h4: {
      fontSize: '16px',
      lineHeight: '24px',
      color: Colors.grey[900],
    },
    h5: {
      // fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '20px',
      color: Colors.grey[900],
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '20px',
      // fontFamily: `'Linden Hill', cursive;`,
      // color: Colors.yanni.red,
    },
  },
  palette: {
    action: {
      active: Colors.grey[700],
      hover: '#A8D3F0A3',
      selected: Colors.blue[100],
    },
    type: 'light',
    primary: {
      light: Colors.teal[500],
      main: '#20A4F3',
      dark: '#2EC4B6',
      contrastText: '#fff',
    },
    secondary: {
      light: Colors.purple[100],
      main: '#20A4F3',
      dark: Colors.purple[900],
      contrastText: '#fff',
    },
    error: {
      main: Colors.red[500],
    },
    background: {
      paper: '#fff',
      default: '#F7F7F7',
    },
    text: {
      primary: '#424242',
      secondary: '#9DAAB6',
    },
  },
  shape: {
    borderRadius: 4,
  },
  shadows: [
    'none',
    'rgba(12, 52, 75, 0.05) 0px 3px 3px', // Card Default
    '0 5px 8px 0 rgba(12, 52, 75, 0.1)', // Tab Bar
    '0 9px 10px 0 rgba(12, 52, 75, 0.1)', // Button Hover
    '0 20px 30px 0 rgba(12, 52, 75, 0.1)',
    '0 30px 30px 0 rgba(12, 52, 75, 0.1)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
    '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
    '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
    '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
    '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
    '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
    '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  ],
  props: {
    MuiLink: {
      underline: 'always',
    },
    MuiButtonBase: {
      disableRipple: false,
      disableTouchRipple: true,
    },
    MuiCardHeader: {
      titleTypographyProps: {
        variant: 'h3',
        gutterBottom: true,
      },
      subheaderTypographyProps: {
        variant: 'caption',
        color: 'textPrimary',
      },
    },
    MuiTabs: {
      indicatorColor: 'primary',
      textColor: 'primary',
    },
    MuiChip: {
      deleteIcon: <XCircle />,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiDialogContentText: {
      color: 'textPrimary',
    },
  },
  overrides: {
    MuiLink: {
      root: {
        fontWeight: 500,
        '&:hover': {
          color: Colors.teal[700],
        },
        '&:active': {
          color: Colors.teal[500],
        },
      },
    },
    MuiToolbar: {
      regular: {
        '@media (min-width: 0px)': {
          minHeight: 72,
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: 4,
      },
    },
    MuiTab: {
      root: {
        minWidth: 0,
        '@media (min-width: 0px)': {
          minWidth: 0,
          fontSize: '16px',
        },
        fontWeight: 'normal',
        fontSize: '16px',
        textTransform: 'none',
        '&$selected': {
          fontWeight: 'bold',
        },
      },
    },
    MuiChip: {
      root: {
        fontSize: 16,
        borderRadius: 8,
        backgroundColor: Colors.grey[100],
        color: Colors.grey[700],
      },
      deleteIcon: {
        height: 24,
        width: 24,
        color: Colors.grey[700],
      },
    },
    MuiIcon: {
      colorAction: {
        color: '#5C6975',
      },
    },
    MuiTypography: {
      root: {
        wordBreak: 'break-word',
      },
    },
    MuiCardHeader: {
      content: {
        overflowX: 'hidden',
      },
      root: {
        padding: '24px 24px 0 24px',
      },
    },
    MuiCardContent: {
      root: {
        padding: 24,
      },
    },
    MuiButton: {
      root: {
        padding: '8px 16px!important',
        border: '2px solid #000',
      },
    },
    MuiContainer: {
      maxWidthLg: {
        '@media (min-width: 1280px)': {
          maxWidth: '1440px',
        },
      },
      maxWidthXl: {
        '@media (min-width: 1920px)': {
          maxWidth: '1576px',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 14,
        lineHeight: '20px',
        marginTop: 4,
      },
    },
    MuiRadio: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent!important',
        },
        '&$checked': {
          color: Colors.green[500] + '!important',
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent!important',
        },
        '&$checked': {
          color: Colors.green[500] + '!important',
        },
      },
    },
    MuiSwitch: {
      switchBase: {
        // color: Colors.green[500],
        '&$checked': {
          color: Colors.green[500],
        },
        '&$checked + $track': {
          backgroundColor: Colors.green[500],
        },
      },
      colorPrimary: {
        '&$checked': {
          color: Colors.green[500],
        },
        '&$checked + $track': {
          backgroundColor: Colors.green[500],
        },
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: '2px solid ' + Colors.grey[500],
      },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: '#F1F4F6',
        },
        '&$hover': {
          '&:hover': {
            backgroundColor: '#E8EDF0',
          },
        },
        '&:nth-child(odd)': { background: '#fff' },
        '&:nth-child(even)': { background: '#F7F7F7' }, // off white
      },
    },
    MuiTableCell: {
      root: {
        padding: 12,
        borderBottom: 'none',
        borderRight: '1px solid ' + Colors.grey[500],
        '&:last-child': {
          borderRight: 'none',
        },
      },
      head: {
        color: Colors.grey[700],
        fontWeight: 'bold',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 32,
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 32px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '24px 32px',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: Colors.grey[300],
      },
    },
  },
})
