import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import '../css/font-awesome.css'
import 'bootstrap/dist/css/bootstrap.css'
import '../css/style.css'
import SEO from './seo'
// TODO: use this logo
import wshLogo from '../images/wsh-ent-logo.jpg'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import { Grid } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import VideocamIcon from '@material-ui/icons/Videocam'
import EventIcon from '@material-ui/icons/Event'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import HomeIcon from '@material-ui/icons/Home'
import StoreIcon from '@material-ui/icons/Store'
import { Colors } from '../theme/Colors'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: `${Colors.yanni.mint}`,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    MenuItem: {
      '&:hover, &:focus': {
        backgroundColor: Colors.yanni.blue,
      },
      '&:active': {
        backgroundColor: Colors.yanni.red,
      },
    },
  })
)

const routes = [
  { label: 'Home', icon: <HomeIcon />, route: '/' },
  // { label: 'About', icon: <InfoIcon />, route: '/about' },
  { label: 'Booking', icon: <EventIcon />, route: '/booking' },
  {
    label: 'Videography',
    icon: <VideocamIcon />,
    route: '/videography',
  },
  {
    label: 'Photography',
    icon: <CameraAltIcon />,
    route: '/photography',
  },
  { label: 'Shop', icon: <StoreIcon />, route: '/store' },
  { label: 'Checkout', icon: <ShoppingCartIcon />, route: '/checkout' },
]

const Header = () => {
  const classes = useStyles()
  const [isDrawerOpen, updateIsDrawerOpen] = React.useState(false)

  const toggleDrawer = () => updateIsDrawerOpen(!isDrawerOpen)

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Typography
        align={'center'}
        variant={'h1'}
        style={{ marginBottom: '30px', fontSize: '20px' }}
      >
        💫Wsh. Ent💫
      </Typography>
      <Divider />
      <List>
        {routes.map((item, index) => (
          <Link to={item.route}>
            <ListItem button key={item.label}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  )
  return (
    <>
      {/* <div className={classes.root}> */}
      <AppBar position={'fixed'} className={classes.root}>
        <SEO
          description={'WSH Entertainment'}
          keywords={[
            'videography',
            'music videos',
            'hip-hop',
            'rap',
            'new york',
            'atlanta',
            'photography',
            'sony',
            'dji drone',
            'streetwear',
            'film production',
          ]}
          title={'WSH entertainment'}
        />
        <Toolbar style={{ display: 'flex' }}>
          <Hidden smUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Box
            align={'center'}
            style={{
              alignItems: 'center',
              width: '100%',
              display: 'inline-flex',
              margin: '10px 0px',
            }}
          >
            <Link to={'/'}>
              <img
                src={wshLogo}
                alt={'logo'}
                style={{
                  margin: 0,
                  borderRadius: '50%',
                  height: '50px',
                }}
              />
            </Link>
          </Box>
          <Grid container>
            <Hidden mdDown>
              {routes.map(r => (
                <Link
                  to={r.route}
                  activeStyle={{ color: Colors.yanni.red }}
                  className={classes.MenuItem}
                  style={{
                    height: '4rem',
                    padding: '0 15px',
                  }}
                >
                  <Typography
                    variant={'h3'}
                    style={{
                      textAlign: 'center',
                      marginTop: '1rem',
                      color: Colors.yanni.white,
                    }}
                  >
                    {r.label}
                  </Typography>
                </Link>
              ))}
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
      <Hidden smUp>
        <Drawer
          variant={'temporary'}
          anchor={'left'}
          open={isDrawerOpen}
          onClose={toggleDrawer}
        >
          {drawer}
        </Drawer>
      </Hidden>
      {/* </div> */}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
